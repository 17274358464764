import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function FelineLeukemiaVaccineCat() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">Feline Leukemia Vaccine</h1>
      <p className="font-18">
        Feline leukemia virus (FeLV) is one of the most dangerous infectious
        diseases in cats, leading to immune system suppression, cancer, and
        other serious health issues. Since there is no cure for FeLV,
        vaccination is the best way to protect your cat from this
        life-threatening virus.
      </p>
      <p>
        Does your cat need the Feline Leukemia vaccine? It's included on our
        Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2 className="my-3 blue">What Is Feline Leukemia (FeLV)?</h2>
        <p>FeLV is a highly contagious virus that spreads through::</p>
        <ul>
          <li>
            Direct contact with infected cats (grooming, sharing food/water
            bowls)
          </li>
          <li>Bites or scratches from infected cats</li>
          <li>Mother-to-kitten transmission during pregnancy or nursing</li>
        </ul>
        <p>
          Once infected, a cat’s immune system becomes compromised, making them
          vulnerable to:
        </p>
        <ul>
          <li>Chronic infections</li>
          <li>Severe anemia</li>
          <li>Cancer (such as lymphoma)</li>
          <li>Organ failure</li>
        </ul>
        <p>
          FeLV-positive cats often have shortened lifespans, and while some may
          live for years, many experience progressive health decline.
        </p>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="feline-leukemia-vaccine-cat"
      title="FeLV Vaccine - Cats"
      description="Learn about the Feline Leukemia vaccination and why it is important for your cat"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default FelineLeukemiaVaccineCat;
