import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function RabiesVaccineCat() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">Rabies Vaccination for Cats</h1>
      <p className="font-18">
        Rabies is a deadly viral disease that affects the brain and nervous
        system of mammals, including cats, dogs, and humans. Since rabies is
        almost always fatal once symptoms appear, vaccination is the only
        effective way to protect your cat from this dangerous virus.
      </p>
      <p>
        Does your cat need the Rabies vaccine? It's included on our Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2 className="my-3 blue">How Does Rabies Spread?</h2>
        <p>
          The rabies virus spreads through bites or scratches from infected
          animals, including wildlife like raccoons, bats, foxes, and skunks.
          Even indoor cats are at risk if they accidentally escape outside or if
          a rabid animal enters your home.
        </p>
      </section>
      <section>
        <h2 className="my-3 blue">
          Why Is the Rabies Vaccine Important for Cats?
        </h2>
        <p>There are many important reasons to have your cat:</p>
        <ul>
          <li>
            <span className="bold roboto me-2">Rabies is fatal</span>- Once
            symptoms develop, there is no cure.
          </li>
          <li>
            <span className="bold roboto me-2">Required by law</span>- Most
            states and cities require rabies vaccinations for cats.
          </li>
          <li>
            <span className="bold roboto me-2">
              Prevents human transmission
            </span>
            - Rabies is a zoonotic disease, meaning it can spread to humans.
          </li>
          <li>
            <span className="bold roboto me-2">Protects indoor cats too</span>-
            Bats and other rabies carriers can enter homes.
          </li>
          <li>
            <span className="bold roboto me-2">
              Prevents quarantine or euthanasia
            </span>
            - Unvaccinated cats exposed to rabies may face strict quarantine or
            euthanasia due to public health concerns.
          </li>
        </ul>
      </section>
      <section>
        <h2 className="my-3 blue">
          Is Your Cat Overdue for Their Rabies Vaccine?
        </h2>
        <p>
          If your cat needs a rabies vaccine, call to schedule an appointment
          today.
        </p>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="rabies-vaccine-cat"
      title="Rabies Vaccine - Cats"
      description="Learn about the Rabies vaccination and why it is important for your cat"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default RabiesVaccineCat;
