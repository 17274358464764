import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function RabiesVaccineDog() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">Rabies Vaccination for Dogs</h1>
      <p className="font-18">
        Vaccinating against Rabies is a key component of preventive care for
        your dog and is legally required in the state of Colorado.
      </p>
      <p>
        Does your dog need the Rabies vaccine? It's included on our Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2 className="my-3 blue">What is Rabies?</h2>
        <p>
          Rabies is a deadly virus that affects the nervous system of mammals,
          including dogs and humans. The virus spreads through saliva, typically
          from the bite of an infected animal. Common carriers include raccoons,
          skunks, foxes, and bats. Rabies is almost always fatal once symptoms
          appear, and there is no cure.
        </p>
      </section>
      <section>
        <h2 className="my-3 blue">Why Is the Rabies Vaccine Important?</h2>
        <p>By vaccinating your dog, you are:</p>
        <ul>
          <li>Protecting them from a fatal disease</li>
          <li>Preventing the spread of rabies to humans and other pets</li>
          <li>Complying with state and local vaccination laws</li>
          <li>
            Avoiding costly post-exposure treatments if your dog is bitten
          </li>
        </ul>
      </section>
      {/*<section>
        <h2 className="my-3 blue">When Should Dogs Get the Rabies Vaccine?</h2>
        <p>
          The rabies vaccination schedule for dogs follows these general
          guidelines:
        </p>
        <ul>
          <li>
            <span className="bold roboto me-2">First vaccine:</span>
            Around 12-16 weeks of age
          </li>
          <li>
            <span className="bold roboto me-2">Puppy booster:</span>
            One year after the initial vaccine
          </li>
          <li>
            <span className="bold roboto me-2">Ongoing boosters:</span>
            Every 3 years
          </li>
        </ul>
        <p>
          For recommendations specific to your dog, please call or schedule an
          appointment.
        </p>
      </section>*/}
      <section>
        <h2 className="my-3 blue">Is the Rabies Vaccine Required by Law?</h2>
        <p>
          Yes, rabies vaccination is legally required for dogs in most states,
          including Colorado. Failure to vaccinate your pet can result in fines
          and legal consequences. More importantly, an unvaccinated dog that
          bites someone may be subject to quarantine or even euthanasia for
          testing. Don't risk it. Make sure your dog is always up to date on
          their Rabies vaccination.
        </p>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="rabies-vaccine-dog"
      title="Rabies Vaccine - Dogs"
      description="Learn about the Rabies vaccination and why it is important for your dog"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default RabiesVaccineDog;
