import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function BordetellaVaccineDog() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">Bordetella Vaccine for Dogs</h1>
      <p className="font-18">
        If your dog spends time around other dogs—whether at boarding
        facilities, dog parks, grooming salons, or daycare—vaccination against
        Bordetella bronchiseptica is essential. The Bordetella vaccine helps
        prevent kennel cough, a highly contagious respiratory infection that
        causes harsh coughing, gagging, and discomfort.
      </p>
      <p>
        Does your dog need the Bordetella vaccine? It's included on our Health
        Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2 className="my-3 blue">What Is Bordetella?</h2>
        <p>
          Bordetella bronchiseptica is a bacterial infection that causes canine
          infectious tracheobronchitis, commonly known as kennel cough. This
          disease spreads through airborne droplets, direct contact, or shared
          surfaces, making dogs in social settings especially vulnerable.
        </p>
        <p>Dogs become infected from exposure to:</p>
        <ul>
          <li>
            Persistent, hacking cough (like something stuck in the throat)
          </li>
          <li>Gagging or retching</li>
          <li>Sneezing and nasal discharge</li>
          <li>Lethargy or mild fever in severe cases</li>
        </ul>
        <p>
          While kennel cough is usually mild, it can lead to secondary
          infections, pneumonia, and severe illness—especially in puppies,
          senior dogs, or dogs with weakened immune systems.
        </p>
      </section>
      <section>
        <h2 className="my-3 blue">Does My Dog Need the Bordetella Vaccine?</h2>
        <p>Your dog needs the Bordetella vaccine if they:</p>
        <ul>
          <li>Attend boarding, daycare, or training classes</li>
          <li>Visit dog parks, groomers, or pet stores</li>
          <li>Participate in dog shows or agility competitions</li>
          <li>Frequently interact with other dogs</li>
        </ul>
        <p>
          Many boarding facilities, groomers, and daycares require proof of
          Bordetella vaccination before accepting dogs to prevent outbreaks.
        </p>
      </section>
      <section>
        <h2 className="my-3 blue">
          How Long Does the Bordetella Vaccine Last?
        </h2>
        <p>
          The Bordetella vaccine is effective for 1 year after administration.
        </p>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="bordetella-vaccine-dog"
      title="Bordetella Vaccine - Dogs"
      description="Learn about the Bordetella vaccination and why it is important for your dog"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default BordetellaVaccineDog;
