import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function DentalCleaningDog() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">
        Professional Dental Cleaning: Keep Your Dog's Teeth Healthy
      </h1>
      <p className="font-18">
        Just like humans, dogs need regular dental care to maintain healthy
        teeth and gums. Without proper cleaning, plaque and tartar build-up can
        lead to bad breath, gum disease, tooth loss, and even serious health
        problems affecting the heart, liver, and kidneys.
      </p>
      {/*<Link to="/pricing">
        <button
          className="btn-filled-primary font-18 mt-5 mb-4"
          style={{ width: "250px" }}
        >
          See Pricing
        </button>
      </Link>*/}
      <section>
        <h2 className="my-3 blue">Does Your Dog Need A Cleaning?</h2>
        <p>
          If your dog hasn't had a cleaning in a while or if they are
          experiencing any dental issues, give us a call today to schedule a
          dental evaluation. We will provide you with an exact quote for the
          procedure your dog needs.
        </p>
      </section>
      <section>
        <h2 className="my-3 blue">
          Why Are Dental Cleanings Important for Your Dog?
        </h2>
        <p>
          Many pet owners don't realize that over 80% of dogs show signs of
          dental disease by the age of three. Dental problems can be painful and
          costly if left untreated.
        </p>
        <p>A professional cleaning helps:</p>
        <ul>
          <li>Remove plaque and tartar buildup</li>
          <li>Prevent gum disease (periodontal disease)</li>
          <li>Stop bad breath (halitosis)</li>
          <li>Reduce the risk of tooth loss</li>
          <li>Prevent infections that can spread to vital organs</li>
        </ul>
        <p>Regular dental care keeps your dog healthy, happy, and pain-free.</p>
      </section>
      <section>
        <h2 className="my-3 blue">Signs Your Dog Needs a Dental Cleaning</h2>
        <p>
          If you notice any of these symptoms, schedule a dental exam as soon as
          possible:
        </p>
        <ul>
          <li>Bad breath (persistent odor)</li>
          <li>Yellow or brown tartar on teeth</li>
          <li>Red, swollen, or bleeding gums</li>
          <li>Difficulty chewing or loss of appetite</li>
          <li>Loose or missing teeth</li>
        </ul>
      </section>
      <section>
        <h2 className="my-3 blue">
          How Often Should Dogs Get a Dental Cleaning?
        </h2>
        <p>
          Most dogs benefit from annual dental cleanings, but some
          breeds—especially small dogs and those prone to dental disease—may
          need more frequent care. Regular brushing at home and dental chews can
          also help maintain oral health between cleanings.
        </p>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="dental-cleaning-dog"
      title="Dental Cleaning - Dogs"
      description="Learn about the importance of routine dental cleanings for your dog"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default DentalCleaningDog;
