import React, { useEffect } from "react";
//import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function DentalExtractionsDog() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">
        Dog Dental Extractions: When and Why They're Necessary
      </h1>
      <p className="font-18">
        Dental health is essential for your dog's overall well-being. While
        regular dental cleanings help maintain healthy teeth, sometimes tooth
        extractions are necessary to relieve pain, prevent infections, and
        improve your dog's quality of life.
      </p>
      {/*<Link to="/pricing">
        <button
          className="btn-filled-primary font-18 mt-5 mb-4"
          style={{ width: "250px" }}
        >
          See Pricing
        </button>
      </Link>*/}
      <section>
        <h2 className="my-3 blue">Does Your Dog Need Extractions?</h2>
        <p>
          If you know or suspect your dog may need tooth extractions, give us a
          call today to schedule a dental evaluation. Our clinic provides
          flat-rate quotes so you know exactly what you will pay for the
          procedure.
        </p>
      </section>
      <section>
        <h2 className="my-3 blue">Why Might a Dog Need a Tooth Extraction?</h2>
        <p>
          Dogs may require dental extractions for several reasons, including:
        </p>
        <ol>
          <li>
            <span className="bold roboto me-2">Severe Periodontal Disease</span>
            <p>
              Gum disease (periodontal disease) is the most common cause of
              tooth extractions in dogs. When plaque and tartar build up,
              bacteria can infect the gums and weaken the tooth's support
              structure, leading to pain, infection, and tooth loss. In advanced
              cases, removing the affected tooth is the only way to prevent
              further damage and relieve discomfort.
            </p>
          </li>
          <li>
            <span className="bold roboto me-2">Tooth Fractures or Trauma</span>
            <p>
              Dogs love to chew, but hard objects like bones, antlers, or rocks
              can crack or break teeth. A fractured tooth exposes the sensitive
              pulp and nerves, causing significant pain and increasing the risk
              of infection. If the damage is severe, extraction is often the
              best option.
            </p>
          </li>
          <li>
            <span className="bold roboto me-2">
              Tooth Root Abscesses & Infections
            </span>
            <p>
              A tooth root abscess occurs when bacteria invade the root of a
              damaged or decayed tooth. This leads to swelling, pain, and pus
              formation. If left untreated, the infection can spread to the
              jawbone and even into the bloodstream, affecting other organs.
              Extracting the infected tooth eliminates the source of the
              problem.
            </p>
          </li>
          <li>
            <span className="bold roboto me-2">Retained Baby Teeth</span>
            <p>
              Puppies sometimes retain their baby teeth, preventing adult teeth
              from coming in properly. This can cause misalignment,
              overcrowding, and increased risk of dental disease. Removing the
              retained teeth ensures proper development and a healthier mouth.
            </p>
          </li>
          <li>
            <span className="bold roboto me-2">Oral Tumors or Growths</span>
            <p>
              In some cases, tumors, cysts, or abnormal growths affect the teeth
              and gums. If a tooth is involved, extraction may be necessary to
              prevent further complications and allow for biopsy or treatment.
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h2 className="my-3 blue">Benefits of Extracting Problem Teeth</h2>
        <p>
          Many pet owners worry about their dog losing teeth, but proceeding
          with recommended extractions offers several benefits:
        </p>
        <ul>
          <li>
            <span className="bold roboto me-2">Pain relief</span>- Eliminates
            constant discomfort from damaged or infected teeth
          </li>
          <li>
            <span className="bold roboto me-2">Prevents infections</span>-
            Reduces the risk of bacteria spreading to other teeth, gums, and
            organs
          </li>
          <li>
            <span className="bold roboto me-2">Improves eating habits</span>-
            Dogs often eat better after painful teeth are removed
          </li>
          <li>
            <span className="bold roboto me-2">Enhances quality of life</span>-
            No more toothaches, swelling, or bad breath!
          </li>
          <li>
            <span className="bold roboto me-2">Stops disease progression</span>-
            Prevents further dental deterioration and more costly treatments
          </li>
        </ul>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="dental-extractions-dog"
      title="Dental Extractions - Dogs"
      description="Learn about dental extractions and why they may be necessary for the health of your dog"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default DentalExtractionsDog;
