import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function FvrcpVaccineCat() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">FVRCP Vaccine for Cats</h1>
      <p className="font-18">
        Keeping your cat healthy starts with preventative care, and one of the
        most important vaccines for cats is the FVRCP vaccine. This core vaccine
        protects against three highly contagious and potentially deadly feline
        diseases: Feline Viral Rhinotracheitis (FVR), Calicivirus (C), and
        Panleukopenia (P).
      </p>
      <p>
        Does your cat need the FVRCP vaccine? It's included on our Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2 className="my-3 blue">What Is the FVRCP Vaccine?</h2>
        <p>The FVRCP vaccine is a combination vaccine that protects against:</p>
        <div className="ms-4">
          <h3>Feline Viral Rhinotracheitis (FVR)</h3>
          <ul>
            <li>Caused by feline herpesvirus (FHV-1)</li>
            <li>Affects the respiratory system, eyes, and nose</li>
            <li>
              Symptoms include sneezing, nasal discharge, conjunctivitis (eye
              infections), and fever
            </li>
            <li>
              Can lead to chronic infections and lifelong flare-ups in some cats
            </li>
          </ul>
          <h3>Feline Calicivirus (FCV)</h3>
          <ul>
            <li>Another major cause of upper respiratory infections in cats</li>
            <li>
              Symptoms include sneezing, eye and nose discharge, ulcers in the
              mouth, and fever
            </li>
            <li>Some strains cause limping syndrome or pneumonia</li>
            <li>
              Highly contagious, especially in shelters or multi-cat households
            </li>
          </ul>
          <h3>Feline Panleukopenia (FPV)</h3>
          <ul>
            <li>
              A deadly viral disease that attacks rapidly dividing cells in the
              body
            </li>
            <li>
              Symptoms include severe vomiting, diarrhea, lethargy, and a
              dangerously low white blood cell count
            </li>
            <li>Highly contagious and often fatal, especially in kittens</li>
            <li>
              The virus can survive in the environment for up to a year, making
              vaccination crucial
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h2 className="my-3 blue">
          Why Is the FVRCP Vaccine Important for Cats?
        </h2>
        <p>
          The FVRCP vaccine is considered a core vaccine, meaning it is
          recommended for all cats, regardless of lifestyle. Even indoor cats
          are at risk, as these viruses can be brought into the home on
          clothing, shoes, or other pets. Vaccination helps:
        </p>
        <ul>
          <li>Prevent severe illness or death from these diseases</li>
          <li>Reduce the spread of contagious viruses</li>
          <li>Protect kittens during their most vulnerable stages</li>
          <li>Ensure long-term immunity and a healthier life</li>
        </ul>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="fvrcp-vaccine-cat"
      title="FVRCP Vaccine - Cats"
      description="Learn about the FVRCP vaccination and why it is important for your cat"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default FvrcpVaccineCat;
