import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function LeptoVaccineDog() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">Leptospirosis Vaccine for Dogs</h1>
      <p className="font-18">
        Leptospirosis is a serious bacterial infection that can affect both dogs
        and humans. The Leptospirosis (Lepto) vaccine protects your pet from
        this potentially fatal disease, which spreads through contaminated
        water, soil, and the urine of infected wildlife.
      </p>
      <p>
        Does your dog need the Lepto vaccine? It's included on our Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2 className="my-3 blue">What Is Leptospirosis?</h2>
        <p>
          Leptospirosis is caused by the{" "}
          <span className="fst-italic">Leptospira</span> bacteria and can lead
          to kidney failure, liver damage, respiratory distress, and even death.
        </p>
        <p>Dogs become infected from exposure to:</p>
        <ul>
          <li>Contaminated water (puddles, lakes, streams)</li>
          <li>Infected wildlife (rats, raccoons, skunks, and deer)</li>
          <li>Wet soil or muddy areas where bacteria thrive</li>
        </ul>
        <p>
          Because Leptospirosis is zoonotic (meaning it can spread to humans),
          vaccinating your dog also helps protect your family.
        </p>
      </section>
      <section>
        <h2 className="my-3 blue">Should My Dog Get the Lepto Vaccine?</h2>
        <p>
          The Leptospirosis vaccine is recommended for all dogs in Colorado.
          However, it is especially important for dogs that:
        </p>
        <ul>
          <li>Spend time outdoors or go hiking, camping, or swimming</li>
          <li>Live in areas with high wildlife populations</li>
          <li>
            Are at risk of exposure to standing water or damp environments
          </li>
          <li>Frequently visit dog parks, daycare, or boarding facilities</li>
        </ul>
      </section>
      {/*<section>
        <h2 className="my-3 blue">When Should Dogs Get the Lepto Vaccine?</h2>
        <p>
          The Lepto vaccination schedule for dogs follows these general
          guidelines:
        </p>
        <ul>
          <li>
            <span className="bold roboto me-2">First vaccine:</span>
            Around 8-12 weeks of age
          </li>
          <li>
            <span className="bold roboto me-2">Puppy booster:</span>
            3-4 weeks after the initial vaccine
          </li>
          <li>
            <span className="bold roboto me-2">Ongoing boosters:</span>
            Every year
          </li>
        </ul>
        <p>
          For recommendations specific to your dog, please call or schedule an
          appointment.
        </p>
      </section>*/}
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="lepto-vaccine-dog"
      title="Leptospirosis Vaccine - Dogs"
      description="Learn about the Leptospirosis vaccination and why it is important for your dog"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default LeptoVaccineDog;
