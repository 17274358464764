import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function VaccinesDog() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-3">Vaccines for Dogs</h1>
      <p className="font-18">
        Vaccinations can entirely prevent or greatly reduce the severity of some
        of the most common and dangerous diseases. Staying current on all
        recommended vaccines is one of the best things you can do for the health
        of your dog.
      </p>
      <p>
        Does your dog need vaccines? They're all included on our Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2>Rabies Vaccine</h2>
        <p>
          Rabies is a fatal viral disease that affects the nervous system of
          dogs, other animals, and humans. It spreads through bites or scratches
          from infected animals and is almost always deadly once symptoms
          appear. The rabies vaccine is legally required in Colorado and
          provides essential protection for your dog.
        </p>
        <Link to="/services/rabies-vaccine-dog">
          <button
            className="btn-filled-primary font-18 mt-3 mb-4"
            style={{ width: "250px" }}
          >
            Learn More
          </button>
        </Link>
      </section>
      <section>
        <h2>DAPP Vaccine</h2>
        <p>
          The DAPP vaccine is a core vaccine that protects against four highly
          contagious and serious canine diseases: distemper, adenovirus,
          parvovirus, and parainfluenza. These diseases can cause severe
          respiratory, gastrointestinal, and neurological symptoms and may be
          fatal, especially in puppies. Keeping your dog up to date on the DAPP
          vaccine is crucial for preventing life-threatening illnesses and
          ensuring long-term health.
        </p>
        <Link to="/services/dapp-vaccine-dog">
          <button
            className="btn-filled-primary font-18 mt-3 mb-4"
            style={{ width: "250px" }}
          >
            Learn More
          </button>
        </Link>
      </section>
      <section>
        <h2>Leptospirosis Vaccine</h2>
        <p>
          Leptospirosis is a bacterial infection that spreads through
          contaminated water, soil, or infected wildlife. It can cause liver and
          kidney failure and is zoonotic, meaning it can spread to humans. The
          leptospirosis vaccine is recommended for all dogs in Colorado,
          especially those exposed to lakes, rivers, or wildlife. Vaccination
          helps protect your dog from severe illness and reduces the risk of
          transmission to other animals and humans.
        </p>
        <Link to="/services/lepto-vaccine-dog">
          <button
            className="btn-filled-primary font-18 mt-3 mb-4"
            style={{ width: "250px" }}
          >
            Learn More
          </button>
        </Link>
      </section>
      <section>
        <h2>Bordetella Vaccine</h2>
        <p>
          The Bordetella vaccine protects against kennel cough, a highly
          contagious respiratory infection that spreads in boarding facilities,
          dog parks, and groomers. Symptoms include a persistent cough,
          sneezing, nasal discharge, and fever. Dogs that frequently interact
          with other dogs should be vaccinated. Many boarding and grooming
          facilities require proof of vaccination. Keeping your dog vaccinated
          helps prevent respiratory infections and ensures they stay healthy in
          social environments.
        </p>
        <Link to="/services/bordetella-vaccine-dog">
          <button
            className="btn-filled-primary font-18 mt-3 mb-4"
            style={{ width: "250px" }}
          >
            Learn More
          </button>
        </Link>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="vaccines-dog"
      title="Vaccines - Dog"
      description="Learn about the different vaccines your dog needs to say healthy"
      className="service-page"
    >
      <Mobile>{content}</Mobile>
      <NotMobile>
        <div className="container">{content}</div>
      </NotMobile>
    </Page>
  );
}
export default VaccinesDog;
