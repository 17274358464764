import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function VaccinesCat() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-3">Vaccines for Cats</h1>
      <p className="font-18">
        Vaccines are essential for protecting cats from serious and potentially
        fatal diseases like rabies, feline leukemia, and upper respiratory
        infections. They help prevent illness, reduce the spread of contagious
        diseases, and ensure long-term health. Even indoor cats benefit from
        vaccination, as some viruses can be transmitted through human contact or
        the environment. Regular vaccinations strengthen the immune system and
        are a key part of responsible pet care, keeping both cats and their
        communities safe.
      </p>
      <p>
        Does your cat need vaccines? They're all included on our Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2>Rabies Vaccine</h2>
        <p>
          Rabies is a fatal viral disease that affects the brain and nervous
          system of cats, other animals, and humans. It is primarily spread
          through bites from infected wildlife, such as bats, raccoons, and
          foxes. Because rabies poses a serious public health risk, vaccination
          is required by law in Colorado. Keeping your cat vaccinated ensures
          lifelong protection against this deadly disease and helps prevent its
          spread in the community.
        </p>
        <Link to="/services/rabies-vaccine-cat">
          <button
            className="btn-filled-primary font-18 mt-3 mb-4"
            style={{ width: "250px" }}
          >
            Learn More
          </button>
        </Link>
      </section>
      <section>
        <h2>FVRCP Vaccine</h2>
        <p>
          The FVRCP vaccine is a core vaccine that protects against three highly
          contagious and potentially fatal feline diseases: feline viral
          rhinotracheitis, calicivirus, and panleukopenia (feline distemper).
          These illnesses can cause severe respiratory infections, mouth ulcers,
          fever, vomiting, diarrhea, and even death. Vaccination is essential
          for both indoor and outdoor cats to prevent infection and ensure
          long-term health.
        </p>
        <Link to="/services/fvrcp-vaccine-cat">
          <button
            className="btn-filled-primary font-18 mt-3 mb-4"
            style={{ width: "250px" }}
          >
            Learn More
          </button>
        </Link>
      </section>
      <section>
        <h2>Feline Leukemia Vaccine</h2>
        <p>
          Feline leukemia virus (FeLV) is a highly contagious and
          life-threatening disease that weakens a cat's immune system, leading
          to chronic infections, anemia, and cancer. It spreads through direct
          contact with infected cats, including grooming, sharing food or water,
          and bites.
        </p>
        <Link to="/services/feline-leukemia-vaccine-cat">
          <button
            className="btn-filled-primary font-18 mt-3 mb-4"
            style={{ width: "250px" }}
          >
            Learn More
          </button>
        </Link>
      </section>
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="vaccines-cat"
      title="Vaccines - Cat"
      description="Learn about the different vaccines your cat needs to say healthy"
      className="service-page"
    >
      <Mobile>{content}</Mobile>
      <NotMobile>
        <div className="container">{content}</div>
      </NotMobile>
    </Page>
  );
}
export default VaccinesCat;
