import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Page from "../common/page";
import { Mobile, NotMobile } from "../common/responsive";

import "../../styles/components/services.scss";

function DappVaccineDog() {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      ReactGA4.initialize(process.env.REACT_APP_GA4_PROPERTY_ID);
      ReactGA4.send("pageview");
    }
  }, []);

  const content = (
    <div className="content">
      <h1 className="mb-4">DAPP Vaccine for Dogs</h1>
      <p className="font-18">
        Keeping your dog healthy starts with proper vaccinations. The DAPP
        vaccine is one of the most important core vaccines for dogs, protecting
        against multiple serious diseases.
      </p>
      <p>
        Does your dog need the DAPP vaccine? It's included on our Health Plan!
      </p>
      <Link to="/health-plans">
        <button
          className="btn-filled-primary font-18 mt-3 mb-4"
          style={{ width: "250px" }}
        >
          Learn More
        </button>
      </Link>
      <section>
        <h2 className="my-3 blue">What Is the DAPP Vaccine?</h2>
        <p>The DAPP vaccine is a combination vaccine that protects against:</p>
        <div className="ms-4">
          <h3>Distemper</h3>
          <p className="ms-3">
            Viral disease affecting the respiratory, gastrointestinal, and
            nervous systems. Often fatal
          </p>
          <h3>Adenovirus</h3>
          <p className="ms-3">
            Causes liver disease, respiratory issues, and eye infections
          </p>
          <h3>Parvovirus</h3>
          <p className="ms-3">
            A severe gastrointestinal virus that causes vomiting, diarrhea,
            dehydration, and often death
          </p>
          <h3>Parainfluenza</h3>
          <p className="ms-3">
            A contagious respiratory virus that contributes to kennel cough
          </p>
        </div>
      </section>
      <section>
        <h2 className="my-3 blue">Does the DAPP Vaccine Go by Other Names?</h2>
        <p>
          Yes. You may hear this vaccine referred to by any of these other
          names:
        </p>
        <ul>
          <li>
            <span className="bold roboto">DHPP</span> Vaccine
          </li>
          <li>
            <span className="bold roboto">DA2PP</span> Vaccine
          </li>
          <li>
            <span className="bold roboto">Distemper</span> Vaccine
          </li>
        </ul>
      </section>
      <section>
        <h2 className="my-3 blue">Why Is the DAPP Vaccine Important?</h2>
        <p>
          These diseases are highly contagious and can spread through direct
          contact, contaminated surfaces, or airborne exposure. Without
          vaccination, dogs are at serious risk of illness, long-term
          complications, or even death. Vaccinating your dog against these
          diseases helps prevent outbreaks and keeps your pet safe.
        </p>
      </section>
      {/*<section>
        <h2 className="my-3 blue">When Should Dogs Get the DAPP Vaccine?</h2>
        <p>
          The DAPP vaccination schedule for dogs follows these general
          guidelines:
        </p>
        <ul>
          <li>
            <span className="bold roboto me-2">First vaccine:</span>
            Around 6-8 weeks of age
          </li>
          <li>
            <span className="bold roboto me-2">Puppy booster:</span>
            3-4 weeks after the initial vaccine
          </li>
          <li>
            <span className="bold roboto me-2">Adult booster:</span>
            One year after the puppy series
          </li>
          <li>
            <span className="bold roboto me-2">Ongoing boosters:</span>
            Every 3 years
          </li>
        </ul>
        <p>
          For recommendations specific to your dog, please call or schedule an
          appointment.
        </p>
      </section>*/}
    </div>
  );

  return (
    <Page
      selectedTab="services"
      selectedLevel2="dapp-vaccine-dog"
      title="DAPP Vaccine - Dogs"
      description="Learn about the DAPP vaccination and why it is important for your dog"
    >
      <div className="service-page">
        <Mobile>{content}</Mobile>
        <NotMobile>
          <div className="container">{content}</div>
        </NotMobile>
      </div>
    </Page>
  );
}
export default DappVaccineDog;
